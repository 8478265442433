<template>
  <div class="pay">
    <ShopNavBar title="支付"></ShopNavBar>

    <div class="container">
      <div class="goods">
        <img :src="getimg(order.goodsModelImg)" class="goods-img" />
        <div class="goods-content">
          <div class="goods-title">{{ order.goodsName }}</div>
          <div class="goods-label">第{{ order.luckyNumber }}期</div>
        </div>
      </div>
      <div class="row-cell">
        <div>购买份数</div>
        <van-stepper v-model="order.buyCount" disabled />
      </div>
      <div class="row-cell">
        <div>每份价值</div>
        <div style="font-size: 18px;">{{order.usdtPrice}} USDT</div>
      </div>
      <div class="row-cell">
        <div>LBD当前价格</div>
        <div style="font-size: 18px;">{{order.tokenPrice}} USDT</div>
      </div>
      <div class="row-cell">
        <div>应投入</div>
        <div style="color: rgba(254, 51, 80, 1);font-weight: bold;">
          <span style="font-size: 20px;">{{ order.amountOrder }}</span> LBD
        </div>
      </div>
      <div class="row-cell">
        <div>支付方式</div>
        <van-radio-group v-model="payWay" direction="horizontal" icon-size="16px">
          <van-radio name="LBD" checked-color="#f8124b">
            <div class="consume-title">LBD</div>
          </van-radio>
          <van-radio name="WLBD" checked-color="#f8124b">
            <div class="consume-title">WLBD</div>
          </van-radio>
        </van-radio-group>
      </div>

      <div class="btn" @click="pay" v-if="order.stateOrder == 0">确认支付</div>
      <div class="btn disable-btn" v-else>{{ order.stateOrderName }}</div>
    </div>
  </div>
</template>

<script>
import { NavBar, Stepper, RadioGroup, Radio } from 'vant';
import { ref } from 'vue';

export default {
  components: {
    [NavBar.name]: NavBar,
    [Stepper.name]: Stepper,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  setup() {
    return {
      us: ref(null),
      order: ref({}),
      payWay: ref("LBD")
    }
  },
  created() {
    this.id = this.$route.query.id
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getShopLucky()
  },
  methods: {
    onBack() {
      this.$router.back()
    },
    getimg(img) {
			if (img != "") {
				return this.$config.send_url + "Upload/" + img;
			}
		},
    // 幸运购详情
    getShopLucky() {
      let _this = this
      _this.$request.post(
        "api/ShopLuckyOrder/Get",
        {
          userid: _this.us.userid,
          luckyOrderId: _this.id,
          payWay: _this.payWay
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            data.goodsModelImg = data.goodsModelImg.split(',')[0]
            _this.order = data
            // console.log(data)
          }
        }
      )
    },
    pay() {
      let _this = this
      _this.$request.post(
        "api/ShopLuckyOrder/PayOrder",
        {
          userid: _this.us.userid,
          luckyOrderId: _this.id
        },
        (res) => {
          _this.$q.dialog({
						message: res.data.msg,
					}).onOk(() => {
            if(res.data.code == 100) {
              _this.$router.replace('luckyBuy?active=3')
            }
          })
          
        }
      )
    }
  }
}
</script>

<style scoped>
.pay {
  background-color: #fff;
  min-height: 100vh;
}

.goods {
  padding: 20px 16px;
  display: flex;
  align-items: center;
  border-bottom: 3px solid rgba(242, 242, 242, 1);
}

.goods-img {
  width: 136px;
  border-radius: 10px;
}

.goods-content {
  margin-left: 13px;
}

.goods-title {
  font-size: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.goods-label {
  color: rgba(153, 153, 153, 1);
}

.row-cell {
  padding: 10px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid rgba(242, 242, 242, 1);
}

.btn {
  margin: 37px 18px 0;
  height: 48px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #fff;
  background: linear-gradient(90deg, rgba(248, 18, 75, 1) 0%, rgba(254, 44, 45, 1) 100%);
}

.disable-btn {
  opacity: .7;
}
</style>